<template>
  <div
    v-if="persons && persons.length > 0"
    class="flex items-center mb-3 md:mb-0 w-full flex-wrap col-span-6 sm:col-span-12"
  >
    <span
      v-if="type === 'newjb' && config.public.site.domainfull === 'mm.dk'"
      class="font-light article-head-m opacity-35"
      >Nyt Job til &nbsp;</span
    >
    <span v-else class="font-light article-head-m opacity-35"
      >{{ $t('About') }} &nbsp;</span
    >
    <ArticleHeadPerson
      v-for="(person, index) in persons.slice(0, 3)"
      :key="index"
      :person="person"
      type="person"
      :padding-right="true"
    />
  </div>
</template>

<script setup lang="ts">
import type { PersonArticleDTO } from '~/typesAuto/apicore/v1'
const config = useRuntimeConfig()

defineProps<{
  type: string
  persons: PersonArticleDTO[]
}>()
</script>
