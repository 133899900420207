<template>
  <div
    class="flex items-center mb-3 md:mb-0 w-full flex-wrap col-span-6 sm:col-span-12"
  >
    <span class="font-light article-head-m opacity-35"
      >{{ $t('Review') }}&nbsp;</span
    >
    <template v-if="article.customWriter">
      <span class="inline-flex relative">
        <span class="article-head-m inline-flex items-center">
          <span
            class="opacity-35 whitespace-nowrap"
            :class="isMm ? 'font-normal' : 'font-medium'"
          >
            {{ article.customWriter }}
          </span>
        </span></span
      >
    </template>
    <ArticleHeadPerson
      v-else-if="writers && writers[0]"
      :person="writers[0]"
      :type="writers[0].type ?? undefined"
    />
  </div>
</template>

<script setup lang="ts">
import type { ArticleDTO, PersonWriterDTO } from '~/typesAuto/apicore/v1'
const { isMm } = useBrand()

withDefaults(
  defineProps<{
    type?: string
    article: ArticleDTO
    writers: PersonWriterDTO[]
  }>(),
  { type: '' }
)
</script>
