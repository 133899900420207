<template>
  <article class="bg-white pb-6 md:pb-12">
    <template v-if="article">
      <CommonQuickEdit :id="article.id" :link="'ArticleEdit'" />
      <ArticleHeadVideo
        v-if="type == 'video' || article.toppictHTML || article.videoProvider"
        :article="article"
        :primaryPaper="primaryPaper"
      />

      <div
        v-if="!article.toppictHTML"
        class="article-head-padding"
        :class="type == 'podcast' ? 'pb-12 -mb-12' : 'bg-white'"
        :style="{ backgroundColor: backgroundColor ?? 'bg-white' }"
      >
        <div v-if="article" class="container">
          <div v-if="type !== 'video'" class="grid-container md:px-11">
            <ArticleHeadPodcast
              v-if="type == 'podcast' && article.podcast"
              :podcast="article.podcast"
              :title="article.headline ?? ''"
              class="md:hidden"
            />
            <ArticleTop
              class="col-span-6 sm:col-span-12 mb-5 md:mb-6 pt-4"
              :article="article"
              :primaryPaper="primaryPaperDto"
            />
            <ArticleHeadReview
              :article="article"
              :writers="writers"
              v-if="type == 'review'"
            />
            <ArticleHeadAnalysis v-if="type == 'analysis'" :persons="writers" />
            <ArticleHeadNames
              v-if="['names', 'newjb'].includes(type)"
              :type="type"
              :persons="article.persons ?? []"
            />
            <ArticleHeadDebate v-if="type == 'debate'" />
            <ArticleHeadHistory v-if="type == 'history'" />
            <ArticleHeadKronik v-if="type == 'kronik'" />
            <ArticleHeadComment
              v-if="
                [
                  'comment',
                  'opinion',
                  'kultur',
                  'chiefblog',
                  'newleadership',
                  'techtendenser',
                  'politicalSpeech',
                  'column',
                ].includes(type)
              "
              :type="getArticleType(article.typeId ?? 0)"
              :writers="writers"
            />
            <ArticleHeadPodcast
              v-if="type == 'podcast' && article.podcast"
              :podcast="article.podcast"
              :title="article.headline ?? ''"
              class="hidden md:flex"
            />
            <h1
              class="headline-m col-span-6 sm:col-span-12"
              :class="isMm ? 'mb-3 md:mb-5' : 'mb-5 md:mb-8'"
              v-html="computedHeadline"
            />
            <div
              v-if="article.mainTeaser"
              class="standfirst col-span-6 sm:col-span-10 mb-5 md:mb-8"
              v-html="article.mainTeaser"
            />
          </div>
        </div>
      </div>

      <div v-if="article" ref="container" class="pt-4 container">
        <div v-if="type !== 'history'" class="grid-container md:px-11">
          <div
            v-if="getToppict"
            class="col-span-6 -mx-4 md:mx-0 sm:col-span-10"
          >
            <figure v-if="!article.toppictHTML" class="mb-3 md:mb-10 max-w-5xl">
              <NuxtPicture
                format="webp"
                sizes="xl:100vw lg:100vw md:100vw sm:100vw xs:100vw"
                quality="80"
                class="w-full"
                :img-attrs="{ class: 'w-full' }"
                :src="getToppict.url ?? undefined"
                :alt="getToppict.text ?? undefined"
                loading="eager"
                :preload="true"
              />
              <figcaption class="max-w-5xl captions mt-2 md:mt-3 px-4 md:px-0">
                <span v-if="getToppict.text" v-html="getToppict.text" />
                <span v-if="getToppict.photographer" class="opacity-35 pl-1"
                  >Foto: {{ getToppict.photographer }}</span
                >
              </figcaption>
            </figure>
          </div>
        </div>
        <div class="grid-container pt-3 md:pt-8 md:px-11">
          <div class="col-span-6 sm:col-span-1">
            <ArticleToolbar
              v-if="article.id"
              class="hidden md:inline-flex"
              :article-id="article.id"
              :show-bookmark="true"
            />
          </div>
          <div ref="content" class="col-span-6 sm:col-span-8 content">
            <ThemeWeeklyTheme
              v-if="
                isMm &&
                article.themeWeek &&
                Object.keys(article.themeWeek).length
              "
              class="mb-5"
              :fixed-theme="article.themeWeek"
              :small-image="true"
              :show-description="true"
            />
            <div v-if="article.customWriter">
              <h4 class="article-label !font-medium">
                {{ article.customWriter }}
              </h4>
              <span
                class="article-label text-black text-opacity-50"
                v-html="article.customWriterDescription"
              />
            </div>
            <template
              v-else-if="
                writers &&
                writers[0] &&
                !(
                  isMm &&
                  [
                    'techtendenser',
                    'chiefblog',
                    'comment',
                    'politicalSpeech',
                    'newleadership',
                    'kultur',
                    'analysis',
                  ].includes(type)
                )
              "
            >
              <template v-for="(writer, index) in writers" :key="index">
                <NuxtLink
                  :to="
                    writer.type === 'writer'
                      ? {
                          name: indexStore.currentPaperSlug
                            ? 'paper_writer'
                            : 'writer',
                          params: {
                            writer: writer.urlKey,
                            paper: indexStore.currentPaperSlug,
                          },
                        }
                      : {
                          name: indexStore.currentPaperSlug
                            ? 'paper_person'
                            : 'person',
                          params: {
                            person: writer.urlKey,
                            paper: indexStore.currentPaperSlug,
                          },
                        }
                  "
                  class="flex items-center mb-3 md:mb-4"
                >
                  <nuxt-img
                    v-if="writer.image"
                    loading="lazy"
                    :alt="writer.name ?? undefined"
                    width="64"
                    :src="
                      isArticleWriter(writer)
                        ? 'https://' +
                          config.public.site.legacyurl +
                          '/images/Writers/' +
                          appendToFilename(writer.image, '-2-48')
                        : writer.masterId && writer.masterId !== 0
                        ? 'https://legacy.altinget.dk/images/person/' +
                          writer.masterId +
                          '/' +
                          writer.image
                        : 'https://' +
                          config.public.site.legacyurl +
                          '/images/person/' +
                          writer.id +
                          '/' +
                          writer.image
                    "
                    class="rounded-full shrink-0 bg-black bg-opacity-10 w-8 h-8 mr-2 overflow-hidden object-cover object-center"
                  />
                  <h4 class="article-label !font-medium">
                    {{ writer.name }}
                  </h4>
                  <span
                    class="article-label text-black text-opacity-50 ml-2"
                    v-html="writer.title"
                  />
                </NuxtLink>
                <div
                  v-if="
                    writer &&
                    [
                      'comment',
                      'opinion',
                      'debate',
                      'politicalSpeech',
                    ].includes(type)
                  "
                  :key="'writer2' + index"
                  class="-mt-2"
                >
                  <div
                    v-if="isArticleWriter(writer) && writer.description"
                    class="body-s writer-description"
                  >
                    <div
                      class="overflow-hidden"
                      :class="minimizeWriter ? 'h-9' : 'h-auto'"
                      v-html="writer.description"
                    />
                    <div class="text-blue list-label">
                      <span class="cursor-pointer" @click="toggleWriter"
                        >{{ minimizeWriter ? $t('ShowMore') : $t('ShowLess')
                        }}<DropdownIcon
                          class="fill-current w-4 h-4 ml-1 inline"
                          :class="[
                            isMm ? '-transform-y-2px transform' : '',
                            !minimizeWriter ? 'rotate-180' : '',
                          ]"
                      /></span>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <div
              v-if="
                ['comment', 'opinion', 'debate', 'kronik'].includes(type) &&
                !isMm
              "
              class="body-s text-black text-opacity-50 mt-5 mb-10"
            >
              {{ $t('OpinionDisclaimer') }}
            </div>
            <ArticleReader
              v-if="!article.paywall && article.textToSpeechFile"
              :file="article.textToSpeechFile"
              :headline="article.headline ?? ''"
              :duration="article.textToSpeechDuration"
              :image="article.images?.[0]?.url ?? ''"
              class="mb-5 md:mb-8"
            />

            <!-- start: Review components -->
            <div v-if="type == 'review'" class="flex mt-10">
              <div class="rounded-lg overflow-hidden mr-3 h-48 shrink-0">
                <img
                  v-if="article.bookCover"
                  class="w-auto h-full"
                  :src="
                    'https://' +
                    config.public.site.legacyurl +
                    '/images/article/' +
                    article.id +
                    '/' +
                    article.bookCover
                  "
                />
              </div>
              <div class="bg-black bg-opacity-5 rounded-lg px-4 pt-3 pb-5">
                <span class="mr-2">
                  <ReviewIcon
                    v-for="item in 6"
                    :key="item"
                    class="w-4 h-4 mr-1px inline-block fill-current"
                    :class="
                      item <= (article.rating ?? 0)
                        ? 'text-red'
                        : 'text-black opacity-10'
                    "
                /></span>
                <div class="list-title-xs mt-2">
                  {{ article.bookTitle }}
                </div>
                <div class="list-title-xs !font-light">
                  {{ article.bookAuthor }}
                </div>
                <div class="body-s mt-5">
                  {{ article.bookInfo }}
                </div>
              </div>
            </div>
            <!-- end: Review components -->

            <ArticleContent
              :article="article"
              @dynamicArticleList="emittedList"
              :class="[
                isMm && type !== 'newjb' ? 'enlargeFirstletter' : '',
                isMm ? `mm-quote-${articlePrimaryPaperUrl}` : '',
              ]"
            />
            <template v-if="!article.paywall">
              <div v-if="article.decisionChainId" class="iframe">
                <iframe
                  class="w-full"
                  :src="`https://${
                    config.public.site.legacyurl
                  }${currentPaper}/decisionchainitem.aspx?iframe=true&id=${
                    article.decisionChainId
                  }${
                    userStore.user?.legacyAutoToken
                      ? `&token=${encodeURI(userStore.user.legacyAutoToken)}`
                      : ''
                  }`"
                ></iframe>
              </div>

              <ArticleHeadPodcast
                v-if="type == 'podcast' && article.podcast"
                :podcast="article.podcast"
                :title="article.headline ?? ''"
              />

              <NewsletterSignup
                v-if="config.public.site.identifier == 'altinget_dk'"
              />

              <template v-if="article.persons && article.persons.length > 0">
                <div class="label-m uppercase text-gray-500 mb-3 mt-8 no-print">
                  {{ $t('MentionedPersons') }}
                </div>
                <CommonDivider v-if="isMm" />
                <template v-for="(person, index) in getPeople" :key="index">
                  <PersonTeaser :person="person" class="no-print" />
                  <CommonDivider v-if="isMm" :key="'divider' + index" />
                </template>

                <div
                  v-if="minimizePeople && article.persons.length > 3"
                  class="flex items-center justify-center"
                >
                  <CommonButton @click.native="togglePeople">
                    {{ $t('ViewAllPeople') }}
                  </CommonButton>
                </div>
              </template>

              <ArticleTags
                v-if="article.tags"
                :tags="article.tags"
                class="my-8 no-print"
              />
              <template v-if="!article.customWriter && !isMm">
                <template v-for="(writer, index) in writers">
                  <div
                    v-if="writer"
                    class="flex flex-col md:flex-row items-center mb-3"
                  >
                    <NuxtLink
                      v-if="writer && writer.urlKey"
                      :to="
                        writer.type === 'writer'
                          ? {
                              name: indexStore.currentPaperSlug
                                ? 'paper_writer'
                                : 'writer',
                              params: {
                                writer: writer.urlKey,
                                paper: indexStore.currentPaperSlug,
                              },
                            }
                          : {
                              name: indexStore.currentPaperSlug
                                ? 'paper_person'
                                : 'person',
                              params: {
                                person: writer.urlKey,
                                paper: indexStore.currentPaperSlug,
                              },
                            }
                      "
                      class="flex items-center"
                    >
                      <nuxt-img
                        v-if="writer.image"
                        loading="lazy"
                        :alt="writer.name ?? undefined"
                        width="64"
                        :src="
                          isArticleWriter(writer)
                            ? 'https://' +
                              config.public.site.legacyurl +
                              '/images/Writers/' +
                              appendToFilename(writer.image, '-2-48')
                            : writer.masterId && writer.masterId !== 0
                            ? 'https://legacy.altinget.dk/images/person/' +
                              writer.masterId +
                              '/' +
                              writer.image
                            : 'https://' +
                              config.public.site.legacyurl +
                              '/images/person/' +
                              writer.id +
                              '/' +
                              writer.image
                        "
                        class="rounded-full shrink-0 bg-black bg-opacity-10 w-8 h-8 mr-2 overflow-hidden object-cover object-center"
                      />
                      <div class="flex items-center" />
                      <h4 class="article-label !font-medium">
                        {{ writer.name }}
                      </h4>
                      <span
                        class="article-label text-black text-opacity-50 ml-2"
                        >{{ writer.title }}</span
                      >
                    </NuxtLink>
                    <a
                      v-if="isArticleWriter(writer) && writer.email"
                      class="md:ml-auto flex items-center"
                      :href="'mailto:' + writer.email"
                    >
                      <span class="article-label !font-medium opacity-50"
                        ><img
                          src="~/assets/icons/mail.svg?url"
                          class="w-4 h-4 opacity-35 mr-2 inline-block"
                        />{{ writer.email }}</span
                      >
                    </a>
                  </div>
                </template>
              </template>
              <CommonDivider class="h-2px mb-10 md:mb-15" />
              <NewsletterSignup
                v-if="config.public.site.identifier != 'altinget_dk'"
              />
            </template>
            <AdsConceptAd
              v-if="isMm"
              :id="`${config.public.site.ads.placementPrefix}-mob2`"
              class="lg:hidden block"
              full-width
            />
          </div>
          <aside class="col-span-6 mt-8 md:mt-0 sm:col-span-3">
            <div class="h-full no-print sidebar">
              <ListMostRead v-if="isMm" />
              <ListNames v-if="!isMm" class="hidden md:block" :count="3" />
              <ListJobs :count="3" :label="'NewestJobs'" />
              <div
                v-if="
                  article.theme &&
                  article.theme.Articles &&
                  article.theme.Articles.length > 0
                "
              >
                <CommonListLabel
                  :label="article.theme.Theme[0].name"
                  :link="
                    currentPaper +
                    '/' +
                    $t('Routes.Subject') +
                    '/' +
                    article.theme.Theme[0].recordId
                  "
                  :no-icon="true"
                  :translate-label="false"
                />
                <CommonDivider />
                <ListArticles
                  :articles="article.theme.Articles"
                  :count="5"
                  placement="side"
                />
              </div>
              <ListMostRead v-else-if="!isMm" class="hidden md:block" />
              <div
                v-if="
                  config.public.site.ads &&
                  config.public.site.adProvider === 'Concept'
                "
                class="sticky top-16"
              >
                <AdsConceptAd
                  :id="`${config.public.site.ads.placementPrefix}-rec1`"
                  class="hidden lg:block"
                />
                <AdsConceptAd
                  v-if="!isMm"
                  :id="`${config.public.site.ads.placementPrefix}-mob2`"
                  class="lg:hidden block"
                  full-width
                />
              </div>
              <PaperTeaser
                v-if="isMm && primaryPaper"
                :paper="primaryPaper"
                type="default"
                class="hidden lg:block"
              />
            </div>
          </aside>
        </div>
      </div>

      <template v-if="!isMm">
        <ListJobs isSlider :count="3" :label="'NewestJobs'" />
      </template>

      <template v-if="isMm">
        <template v-if="seriesId || themeId">
          <template v-if="seriesId">
            <CommonSectionLabel
              :title="series?.pageTitle ?? ''"
              subtitle=""
              :link="`serie/${series?.id}`"
            />
            <ListArticles
              class="mb-6 md:mb-16"
              :slider="true"
              :subject="seriesId"
              :count="10"
              :frontend="false"
              type="subject"
            />
          </template>
          <template v-else-if="themeId">
            <CommonSectionLabel title="Læs også" subtitle="" />
            <ListArticles
              class="mb-6 md:mb-16"
              :slider="true"
              :subject="themeId"
              type="subject"
              :frontend="false"
              :count="10"
            />
          </template>
          <template v-else>
            <CommonSectionLabel title="Læs også" subtitle="" />
            <ListArticles
              class="mb-6 md:mb-16"
              :slider="true"
              :article-type="typeNumber"
              :count="10"
            />
          </template>
        </template>
        <template v-else-if="typeNumber && typeNumber !== 1">
          <CommonSectionLabel title="Læs også" subtitle="" />
          <ListArticles
            class="mb-6 md:mb-16"
            :slider="true"
            :article-type="typeNumber"
            :count="10"
          />
        </template>
      </template>
      <template v-if="config.public.site.ads">
        <AdsConceptAd
          :id="`${config.public.site.ads.placementPrefix}-lb2`"
          full-width
          class="hidden md:block"
        />
        <AdsConceptAd
          :id="`${config.public.site.ads.placementPrefix}-mob3`"
          class="lg:hidden block"
          full-width
        />
      </template>
    </template>
  </article>
</template>

<script setup lang="ts">
import DropdownIcon from '@/assets/icons/dropdown.svg?component'
import ReviewIcon from '@/assets/icons/rating.svg?component'
import type {
  ArticleWriterDTO,
  PersonWriterDTO,
  ArticleDTO,
} from '~/typesAuto/apicore/v1'
const { isMm } = useBrand()

const route = useRoute()
const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()
const indexStore = useIndexStore()
const userStore = useUserStore()
const dataLayer = useDataLayer()

const props = defineProps<{ id: string }>()

const dynamicArticleList = ref<(HTMLParagraphElement | HTMLQuoteElement)[]>()
const currentPaperId = indexStore.currentPaper?.RecordId ?? 1

const trackArticleView = async (article: ArticleDTO) => {
  dataLayer.article.view(article)
}

const asyncDataKey = `${props.id}-${route.name?.toString()}-${currentPaperId}`
const {
  data: article,
  refresh,
  error,
} = await useAsyncData(asyncDataKey, async () => {
  const routeName = route.name?.toString()
  if (!routeName) {
    return
  }
  if (routeName.includes('article')) {
    const article: ArticleDTO = await nuxtApp.$api.content.articleFull(
      props.id,
      currentPaperId,
      route.query?.toke?.toString()
    )

    trackArticleView(article)
    return article
  } else if (routeName.includes('names') || routeName.includes('jobchange')) {
    return await nuxtApp.$api.content.articleFull(
      props.id,
      currentPaperId,
      route.query?.toke?.toString()
    )
  }
})
const computedHeadline = computed(() => {
  return article.value?.headline
})

const articlePrimaryPaperUrl = computed(() => {
  return (
    article.value?.papers?.find((paper) => paper.primary === 1)?.paperUrl ?? ''
  )
})

// emit from child component with list of dynamic article elements
const emittedList = (list: (HTMLParagraphElement | HTMLQuoteElement)[]) => {
  if (list.length && isSite('altinget_no')) {
    injectAds(list)
    dynamicArticleList.value = list
  }
}

// Reload article when logging in/out
watch([() => userStore.isLoggedIn, () => userStore.user?.papers], async () => {
  await refresh()
  if (dynamicArticleList.value && isSite('altinget_no')) {
    injectAds(dynamicArticleList.value)
  }
})

// Refetch the article when loading on client
onNuxtReady(async () => {
  // Article might be in preview mode and not have an paywall. In that case we want to refresh
  // if the user works for Altinget
  if (
    (userStore.isLoggedIn && article.value?.paywall) ||
    userStore.emailBelongsToAlrow ||
    route.query.preview === 'true'
  ) {
    await refresh()
  }
  // If we throw the error before mount on client, we'll never get to this function.
  // That's problematic in the case of articles that aren't published, but should be
  // previewable by altinget users
  if (error.value) {
    throw createError({
      statusCode:
        (error.value as Error & { statusCode: number }).statusCode ?? 404,
      message: error.value.message,
    })
  }
})

const type = computed(() => {
  if (route.name?.toString().includes('names')) {
    if (article.value && article.value.subType === 3) {
      return 'newjb'
    }
    return 'names'
  } else if (
    route.name?.toString().includes('article') &&
    typeof article.value === 'object' &&
    article.value?.typeId
  ) {
    return getArticleType(article.value.typeId)
  } else {
    return 'article'
  }
})

function isArticleWriter(
  object: ArticleWriterDTO | PersonWriterDTO
): object is ArticleWriterDTO {
  return (object as ArticleWriterDTO)?.type === 'writer'
}

const writers = computed((): (ArticleWriterDTO | PersonWriterDTO)[] => {
  return [
    ...(article.value?.articleWriters ?? []),
    ...(article.value?.personWriters ?? []),
  ]
})

function togglePeople() {
  minimizePeople.value = false
}
function toggleWriter() {
  minimizeWriter.value = !minimizeWriter.value
}

const backgroundColor = computed(() => {
  if (!(article.value?.podcast && article.value.podcast.color)) return
  return hexToRgba(article.value.podcast.color, 0.1)
})

const getToppict = computed(() => {
  if (article.value?.images) {
    return article.value?.images.find((x) => x.toppict === 1)
  } else {
    return null
  }
})

const minimizePeople = ref(true)
const minimizeWriter = ref(true)

const getPeople = computed(() => {
  if (minimizePeople.value) {
    return article.value?.persons?.slice(0, 3)
  } else {
    return article.value?.persons
  }
})

const typeNumber = computed(() => article.value?.typeId ?? 0)
const seriesId = computed<number | undefined>(() => {
  if (!article.value?.tags) return undefined

  return article.value?.tags?.find((x) => x.type === 12)?.id ?? undefined
})
const series = computed(() => {
  if (!article.value?.tags) return undefined

  return article.value?.tags?.find((x) => x.type === 12)
})

const themeId = computed<number | undefined>(() => {
  if (!article.value?.tags) return undefined

  return article.value?.tags?.find((x) => x.type === 11)?.id ?? undefined
})

const currentPaper = computed(() => {
  if (
    indexStore.currentPaper &&
    currentPaperId &&
    indexStore.currentPaper.Url
  ) {
    return `/${indexStore.currentPaper.Url}`
  } else {
    return ''
  }
})

const primaryPaperDto = computed(() => {
  return article.value?.papers?.find((paper) => paper.primary === 1)
})

const primaryPaper = computed(() => {
  if (primaryPaperDto.value?.id) {
    return indexStore.papers?.find(
      (x) => x.RecordId === primaryPaperDto.value?.id
    )
  }
})

const content = ref<HTMLDivElement>()

function initFactboxesAndEmbeds() {
  if (import.meta.client && document && article.value && article.value.text) {
    if (article.value.text.includes('twitter-tweet')) {
      // twttr is a global variable injected by Twitter. TypeScript doesn't know about it so it gets mad if we don't use ts-ignore. And ESLint doesn't like ts-ignore so we have to disable it.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window?.twttr) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window?.twttr?.widgets?.load()
      } else {
        const script = document.createElement('script')
        script.setAttribute('src', 'https://platform.twitter.com/widgets.js')
        document.head.appendChild(script)
      }
    }

    if (article.value.text.includes('instagram-media')) {
      const script = document.createElement('script')
      script.setAttribute('src', 'https://www.instagram.com/embed.js')
      document.head.appendChild(script)
    }
  }

  if (content.value) {
    const factboxes = content.value.getElementsByClassName(
      'factbox'
    ) as HTMLCollectionOf<HTMLDivElement>
    Array.from(factboxes).forEach((factbox) => {
      if (
        !(
          (factbox.classList.contains('C') ||
            factbox.classList.contains('center') ||
            factbox.classList.contains('left') ||
            factbox.classList.contains('right') ||
            window.innerWidth <= 768) &&
          factbox.offsetHeight > 300
        )
      ) {
        return
      }

      const factboxShadow = document.createElement('div')
      factboxShadow.className = factbox.className
      factboxShadow.classList.remove('factbox')
      factboxShadow.classList.add('factbox-shadow')
      factboxShadow.classList.add('collapsed')
      factbox.insertAdjacentElement('afterend', factboxShadow)

      factbox.classList.add('collapsed')
      factbox.classList.add('collapsible')

      const wrapper = document.createElement('div')
      wrapper.classList.add('collapse-wrapper')

      const button = document.createElement('div')
      button.className =
        'collapse-button border whitespace-nowrap cursor-pointer inline-flex items-center transition-colors duration-100 select-none shrink-0 justify-center ml-5 flex medium border-none bg-blue hover:bg-gray-300 text-white hover:text-gray-900 button-l px-4 rounded-full'
      const text = document.createTextNode(nuxtApp.$t('ShowMore'))
      button.appendChild(text)
      button.addEventListener('click', () => {
        if (factbox.classList.contains('collapsed')) {
          factboxShadow.classList.remove('collapsed')
          factbox.classList.remove('collapsed')
          button.innerHTML = nuxtApp.$t('ShowLess')
        } else {
          factbox.classList.add('collapsed')
          factboxShadow.classList.add('collapsed')
          button.innerHTML = nuxtApp.$t('ShowMore')
        }
      })

      const gradient = document.createElement('div')
      gradient.classList.add('collapse-gradient')

      wrapper.appendChild(button)
      wrapper.appendChild(gradient)
      factboxShadow.appendChild(wrapper)
    })
  }
}

watch(
  article,
  () => {
    initFactboxesAndEmbeds()
  },
  { immediate: true }
)

onMounted(() => {
  initFactboxesAndEmbeds()
})

const getMetaTags = () => {
  if (
    import.meta.client &&
    article.value &&
    (article.value as ArticleDTO).redirectUrl
  ) {
    const typedArticle = article.value as ArticleDTO
    const redirectString = typedArticle.redirectUrl as string
    const redirectUrl = new URL(redirectString)

    if (
      // LINK OFFSITE
      redirectUrl.host !== config.public.site.url ||
      // LINK DIFFERENT BUT SAME PAGE
      redirectUrl.pathname !== route.path
    ) {
      navigateTo(redirectUrl.pathname, { replace: true })
      return {}
    }
  }

  let titletmp = config.public.site.meta.title.postfix.long

  if (!article.value) {
    return {
      title: titletmp,
    }
  }

  if (
    config.public.site.identifier !== 'mm_dk' &&
    indexStore.currentPaper &&
    indexStore.currentPaper.RecordId > 1
  ) {
    titletmp =
      config.public.site.meta.title.postfix.short + indexStore.currentPaper.Name
  }

  const twitterCreator =
    isArticleWriter(writers.value[0]) && writers.value[0]?.twitterName
      ? `@${writers.value[0].twitterName}`
      : config.public.site.twitter

  let metaData = [
    {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      hid: 'twitter:site',
      name: 'twitter:site',
      content: `@${config.public.site.twitter}`,
    },
    {
      hid: 'og:site_name',
      name: 'og:site_name',
      content: indexStore.currentPaper?.Name,
    },
  ] as any
  if (route.name?.toString().includes('article')) {
    titletmp = article.value.headline
      ? `${article.value.headline} - ${titletmp}`
      : titletmp
    metaData = [
      ...metaData,
      {
        hid: 'twitter:creator',
        name: 'twitter:creator',
        content: twitterCreator,
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: article.value.headline,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: striphtml(article.value.mainTeaser ?? ''),
      },
      {
        hid: 'twitter:image:src',
        name: 'twitter:image:src',
        content: article.value.images?.find((x) => x.toppict === 1)?.url,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: article.value.headline,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: striphtml(article.value.mainTeaser ?? ''),
      },
      { hid: 'og:type', property: 'og:type', content: 'article' },
      {
        hid: 'og:url',
        property: 'og:url',
        content: `https://www.${
          config.public.site.domainfull +
          (indexStore.currentPaper?.Url
            ? `/${indexStore.currentPaper?.Url}`
            : '')
        }/${nuxtApp.$t('Routes.Article')}/${article.value.urlKey}`,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: article.value.images?.find((x) => x.toppict === 1)?.url,
      },
      {
        hid: 'article:published_time',
        name: 'article:published_time',
        content: article.value.createTime,
      },
      article.value.lastUpdate
        ? {
            hid: 'article:modified_time',
            name: 'article:modified_time',
            content: article.value.lastUpdate,
          }
        : {},
    ]
  } else if (route.name?.toString().includes('names')) {
    titletmp = article.value.headline
      ? article.value.headline + titletmp
      : titletmp
    metaData = [
      ...metaData,
      {
        hid: 'twitter:creator',
        name: 'twitter:creator',
        content: twitterCreator,
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: article.value.headline,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: article.value.mainTeaser,
      },
      {
        hid: 'twitter:image:src',
        name: 'twitter:image:src',
        content: article.value.images?.find((x) => x.toppict === 1)?.url,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: article.value.headline,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: striphtml(article.value.mainTeaser ?? ''),
      },
      { hid: 'og:type', property: 'og:type', content: 'article' },
      {
        hid: 'og:url',
        property: 'og:url',
        content: `https://www.${
          config.public.site.domainfull +
          (indexStore.currentPaper?.Url
            ? `/${indexStore.currentPaper?.Url}`
            : '')
        }/${nuxtApp.$t('Routes.Names')}/${article.value.urlKey}`,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: article.value.images?.find((x) => x.toppict === 1)?.url,
      },
      {
        hid: 'article:published_time',
        name: 'article:published_time',
        content: article.value.createTime,
      },
      article.value.lastUpdate
        ? {
            hid: 'article:modified_time',
            name: 'article:modified_time',
            content: article.value.lastUpdate,
          }
        : {},
    ]
  }

  return {
    title: titletmp,
    meta: metaData,
  }
}
useHead(() => {
  return getMetaTags()
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/articleScoped.css';
</style>
<style lang="scss">
@import '@/assets/css/article.css';
</style>
